<template>
  <div id="news-painel" class="container-fluid px-0">
    <div class="container-fluid container-xxl min-vh-100">
      <div class="row mx-0 text-white py-3">
        <div class="col-auto">Bem Vindo, <strong>{{ getName }}</strong></div>
        <div class="col text-right">
          <button @click="logOut" class="btn btn-danger"> Sair
            <b-icon icon="box-arrow-right" />
          </button>
        </div>
        <h2 class="text-center text-white">Painel das Notícias</h2>
      </div>
      <transition name="anim" mode="out-in">
        <router-view @Auth="logOut()" />
      </transition>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    beforeMount() {
      this.$store.commit('GET_AUTH');
      if (!this.$store.state.news.login.token) {
        this.swal(false, 'Usuário sem autorização!')
        localStorage.removeItem('auth')
        this.$router.push('/noticias/login')
      }
    },
    computed: {
      getName() {
        return this.$store.state.news.login.nome;
      }
    },
    methods: {
      logOut() {
        this.$store.dispatch('logout', {})
        this.$router.push('/noticias/login')
      },
    }
  }
</script>

<style scoped>
  .min-vh-100 {
    overflow: hidden;
  }

  [id*='news-'] {
    background: #000428;
    background: -webkit-linear-gradient(to bottom, #004e92, #000428);
    background: linear-gradient(to bottom, #004e92, #000428);
    overflow-x: hidden;
  }

  #conteudo {
    min-height: calc(100vh - 108px - 50px);
  }

  .anim-enter-active {
    min-width: 100%;
    animation: homeIn 0.5s;
  }

  .anim-leave-active {
    min-width: 100%;
    animation: homeOut 0.5s;
  }

  @keyframes homeOut {
    from {
      transform: perspective(1000px) rotateY(0) rotateX(0) rotateZ(0);
      opacity: 0.5;
    }

    to {
      transform: perspective(1000px) rotateY(90deg) rotateX(0) rotateZ(0);
      opacity: 1;
    }
  }

  @keyframes homeIn {
    from {
      transform: perspective(1000px) rotateY(-90deg) rotateX(0) rotateZ(0);
      opacity: 0.5;
    }

    to {
      transform: perspective(1000px) rotateY(0) rotateX(0) rotateZ(0);
      opacity: 1;
    }
  }
</style>